import {ref} from "vue";
import { useLoading } from "vue-loading-overlay";
import toast from "@/helpers/toast";
import FundraisingHost from '@/helpers/FundraisingHost'

const $loading = useLoading();
const donation = ref(0);
const {success,error} = toast;
const selectedProjectId = ref();
const amount  = ref()
const currency = ref('UGX');
const customer = ref();
const donor_name = ref();
const donor_organization = ref();
const donated_to_project_id = ref();
const email = ref()
const donor_mm_number = ref()
const tx_status = ref();
const mobile_carrier = ref('');
const disableCheckout = ref(false);
const validMobileNumber = ref(false);

const makeDonation = async () => {


  if (donor_name.value == null || donor_name.value.length < 1 ) {

    error('Please enter a donor name')
    return;

  }

  if (donor_mm_number.value.length < 12){

    error('Please check the length of your phone number')
    return;

  }

  if (mobile_carrier.value == '') {

    error('Please select a source of funds')
    return;

  }

  if (validMobileNumber.value == false){

    error('Please input a valid phone number')
    return;

  }


  const loader = $loading.show({
    color: "#2581ef",
    opacity: .7,
  });

  const donation = {
    'amount' : amount.value.toString(),
    'currency' : currency.value,
    'donor_name' : donor_name.value,
    'donor_organization' : donor_organization.value,
    'donor_mm_number': donor_mm_number.value.trim().replace(/\s/g, '').replace(/\+/g, ""),
    'donated_to_project_id' : donated_to_project_id.value.toString(),
    'mobile_network':      mobile_carrier.value.toString()

  }
  const res = await fetch(`${FundraisingHost.url}donate` ,{
    method: 'POST',
    body:JSON.stringify(donation),
    headers:{
      'Content-Type': 'application/json',
      'Accept':'application/json, text/plain',
      'Authorization' : 'Bearer '+localStorage.getItem('token')
    }
  });

  const data =  await res.json();
  if (data.status === 'success') {

    disableCheckout.value = true;
    // console.log(data);
    loader.hide();
    success(data.message);

    setTimeout(()=>{
      location.href = "/payment_confirmation?tx_ref=" + data.tx_ref + "&request_id=" + data.request_id;
    },2000);

  }else{

    disableCheckout.value = false;
    loader.hide();
    error(data.message);

  }
}


const sendDonationConfirmation = async (donation : any) => {
  const loader = $loading.show({
    color: "#2581ef",
    opacity: .7,
  });

  const res = await fetch(`${FundraisingHost.url}donation-confirmation` ,{
    method: 'POST',
    body:JSON.stringify(donation),
    headers:{
      'Content-Type': 'application/json',
      'Accept':'application/json, text/plain',
      'Authorization' : 'Bearer '+localStorage.getItem('token')
    }
  });

  const data =  await res.json();
  if (data.status === 'error') {
    error(data.message);
  }
  tx_status.value = data.tx_status
  loader.hide();
}



export default {donation,selectedProjectId,customer,currency,amount,
  donor_name, donor_organization, donated_to_project_id, donor_mm_number, makeDonation, sendDonationConfirmation, tx_status, mobile_carrier,
  disableCheckout, validMobileNumber
}