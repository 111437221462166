<template>
  <div class="mt-8">
    <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
      <button @click.prevent="$router.back()" class="capitalize flex text-blue-500 hover:underline">
        <ArrowLeftIcon class="mr-2 h-6 w-6" />
        back
      </button>
      <div class="flex justify-center items-center">
        <h2 class="text-xl leading-6 font-medium text-gray-900">Add {{selectedTemplate.template_name}} Template Data</h2>
      </div>
    </div>
    <div class="mt-12 max-w-2xl mx-auto">
      <form class="" id="create-resource" enctype="multipart/form-data" method="post">
        <input v-if="loggedInUser" type="hidden" name="created_by_id" :value="loggedInUser.id">
        <div class="space-y-8 divide-y divide-gray-200">
          <div>
            <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <input  type="hidden" name="_method" value="PATCH">
              <div class="sm:col-span-6" v-for="(field,index) in dataFields" :key="index">
                <label for="resource_name" class="block text-sm font-medium text-gray-700 capitalize">
                 {{field.field}}
                </label>
                <div class="mt-1 flex rounded-md shadow-sm">
                  <input :type="field.type === 'integer'? 'number': field.type" v-model="field.data" name="resource_name" id="resource_name" autocomplete="resource_name" class="flex-1 px-3 focus:ring-indigo-500 border py-3 focus:border-indigo-500 block w-full min-w-0  rounded-md sm:text-sm border-gray-300" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-center sm:col-span-6">
          <button @click.prevent="addTemplateData"  class="block bg-blue-600 hover:bg-blue-500 p-2 rounded-lg w-1/2 mx-auto text-white mt-4 text-center px-40 text-center mx-auto">Save</button>

        </div>
      </form>
    </div>
  </div>
</template>

<script>
import user from "@/store/User";
import template from "@/store/Template";
import {onMounted} from "vue";
import {ArrowLeftIcon} from '@heroicons/vue/solid'
export default {
  name: "DashboardAddTemplateData",
  components:{ArrowLeftIcon},
  setup(){
    const {loggedInUser} = user;
    const {selectedTemplate,dataFields,addTemplateData} = template;
    onMounted(()=>{
      if (selectedTemplate) {
        dataFields.value = [];
        for (let i = 0; i < selectedTemplate.value.template_fields.length; i++) {
          const t = {};
          t['field'] = selectedTemplate.value.template_fields[i].name;
          t['data'] = null;
          t['type'] = selectedTemplate.value.template_fields[i].type;
          dataFields.value.push(t);
        }
      }
    });
    return{
      loggedInUser,selectedTemplate,dataFields,addTemplateData
    }
  }

};
</script>

<style scoped>

</style>