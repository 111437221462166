import { computed, ref } from "vue";
import toast from "@/helpers/toast";
import {useLoading} from 'vue-loading-overlay'
import FundraisingHost from '@/helpers/FundraisingHost'

const $loading = useLoading();
const collectionList = ref(<any[]>[])
const {success,error} = toast;
const selectedCollection = ref();
const filter = ref('');



const create = async (user_id= 0) => {
  const loader = $loading.show({
    color: "#2581ef",
    opacity: .7,
  });
  const f =  document.getElementById('create-collection')  as HTMLInputElement;
  // @ts-ignore
  const form  =  new FormData(f);
  const  res = await fetch(FundraisingHost.url+'create-project-collection',{
    method:'post',
    body: form,
    headers:{
      'Accept':'application/json, text/plain',
      'Authorization' : 'Bearer '+localStorage.getItem('token')
    }
  });

  const data =  await res.json();
  if (data.status === 'success'){
    if (f){
      // @ts-ignore
      f.reset();
    }
    success(data.message);
    loadCollections(user_id)
  }else{
    error(data.message);
  }
  loader.hide();

}

//
// const update = async (user_id:any) => {
//   const loader = $loading.show({
//     color: "#2581ef",
//     opacity: .7,
//   });
//   const f = document.getElementById('create-donations');
//   const form  =  new FormData(f);
//   const  res = await fetch(`${FundraisingHost.url}update-project-resource/${selectedDonation.value.id}/${user_id}`,{
//     method:'PATCH',
//     body: form,
//     headers:{
//       'Accept':'application/json, text/plain',
//       'Authorization' : 'Bearer '+localStorage.getItem('token')
//     }
//   });
//
//   const data =  await res.json();
//   if (data.status === 'success'){
//     success(data.message);
//     loadCollections(user_id)
//   }else{
//     error(data.message);
//   }
//   loader.hide();
//
// }

const loadCollections = async (user_id = 0) => {
  const loader = $loading.show({
    color: "#2581ef",
    opacity: .7,
  });
  const res = await fetch(`${FundraisingHost.url}get-collections-list/${user_id}` ,{
    headers:{
      'Content-Type': 'application/json',
      'Accept':'application/json, text/plain',
      'Authorization' : 'Bearer '+localStorage.getItem('token')
    }
  });

  const data =  await res.json();
  if (data.status === 'success') {
    collectionList.value = data.collections;
    // success(data.message)
  }else{
    error(data.message)
  }
  loader.hide();
}

const loadProjectCollections = async (project_id:any,year:number, month:number) => {
  const loader = $loading.show({
    color: "#2581ef",
    opacity: .7,
  });
  const res = await fetch(`${FundraisingHost.url}get-project-collections-list/${project_id}/${month}/${year}` ,{
    headers:{
      'Content-Type': 'application/json',
      'Accept':'application/json, text/plain',
      'Authorization' : 'Bearer '+localStorage.getItem('token')
    }
  });

  const data =  await res.json();
  if (data.status === 'success') {
    collectionList.value = data.project_collections;
    // success(data.message)
  }else{
    error(data.message)
  }
  loader.hide();
}

const getCollectionList = computed(()=>{
  return collectionList.value.filter((val)=>val.equipment_name.toLowerCase().indexOf(filter.value.toLowerCase()) !== -1)
})


export default {loadCollections,collectionList,create,selectedCollection,loadProjectCollections,getCollectionList,filter}