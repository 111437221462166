<template>
  <div class="bg-gray-100 h-screen">
    <div v-if="tx_status === 'successful'" class="bg-white p-6  md:mx-auto">
      <svg viewBox="0 0 24 24" class="text-green-600 w-16 h-16 mx-auto my-6">
        <path fill="currentColor"
              d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z">
        </path>
      </svg>
      <div class="text-center">
        <h3 class="md:text-2xl text-base text-gray-900 font-semibold text-center">Donation Payment complete!</h3>
        <p class="text-gray-600 text-sm my-2 italic">Transaction Ref: {{ transactionData['tx_ref'] }}</p>
        <p class="text-gray-600 my-2">Thank you for  your donation .</p>
        <p> Have a great day!  </p>
        <div class="py-10 text-center">
          <a :href="'/show/'+id" class="px-12 bg-blue-600 hover:bg-blue-500 text-white font-semibold py-3 rounded-lg">
            GO BACK TO PROJECT
          </a>
        </div>
      </div>
    </div>
    <div v-else-if="tx_status === 'pending'" class="bg-white p-6  md:mx-auto">
      <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="text-red-600 w-16 h-16 mx-auto my-6" viewBox="0 0 16 16">
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
      </svg> -->
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="text-yellow-600 w-16 h-16 mx-auto my-6" viewBox="0 0 16 16">         <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.15.15 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.2.2 0 0 1-.054.06.1.1 0 0 1-.066.017H1.146a.1.1 0 0 1-.066-.017.2.2 0 0 1-.054-.06.18.18 0 0 1 .002-.183L7.884 2.073a.15.15 0 0 1 .054-.057m1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767z"/>
        <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"/>
      </svg>
      <div class="text-center">
        <h3 class="md:text-2xl text-base text-gray-900 font-semibold text-center">Donation Payment Pending!</h3>
        <p class="text-gray-600 my-2">We are still processing your Donation</p>
        <p class="text-gray-600 my-2 font-semibold">Please check your mobile phone to approve payment</p>
        <p class="text-gray-600 my-2">Afterwards, you can reload this page to verify payment</p>
        <p class="text-gray-600 text-sm my-2 italic">Transaction Ref: {{ transactionData['tx_ref'] }}</p>
        <div class="py-10 text-center">
          <a :href="'/show/'+id" class="px-12 bg-blue-600 hover:bg-blue-500 text-white font-semibold py-3 rounded-lg">
            GO BACK TO PROJECT
          </a>
        </div>
      </div>
    </div>
    <div v-else-if="tx_status === 'error'" class="bg-white p-6  md:mx-auto">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="text-red-600 w-16 h-16 mx-auto my-6" viewBox="0 0 16 16">
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
      </svg>
      <div class="text-center">
        <h3 class="md:text-2xl text-base text-gray-900 font-semibold text-center">Donation Payment Failed!</h3>
        <p class="text-gray-600 my-2">We have failed to process your Donation</p>
        <p class="text-gray-600 text-sm my-2 italic">Transaction Ref: {{ transactionData['tx_ref'] }}</p>
        <div class="py-10 text-center">
          <a :href="'/show/'+id" class="px-12 bg-blue-600 hover:bg-blue-500 text-white font-semibold py-3 rounded-lg">
            GO BACK TO PROJECT
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useRoute} from 'vue-router'
import {onMounted}  from 'vue'
import checkout from "@/store/Checkout";
export default {
  name: "Status",
  setup(){
    const route = useRoute();
    const id = window.localStorage.getItem('id');
    const {sendDonationConfirmation,tx_status} = checkout;
    let transactionData = route.query
    onMounted(() => {
      sendDonationConfirmation(route.query)
    })
    return{
      id, tx_status,transactionData
    }
  }
};
</script>

<style scoped>

</style>