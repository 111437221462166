<template>
  <div class="mt-8">
    <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
      <button @click.prevent="$router.back()" class="capitalize flex text-blue-500 hover:underline">
        <ArrowLeftIcon class="mr-2 h-6 w-6" />
        back
      </button>
      <div class="flex justify-center items-center">
        <h2 class="text-xl leading-6 font-medium text-gray-900">Add User</h2>
      </div>
    </div>
    <div class="mt-12 max-w-2xl mx-auto">
      <form class="space-y-8 divide-y divide-gray-200">
        <div class="space-y-8 divide-y divide-gray-200">
          <div>

            <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div class="sm:col-span-6">
                <label for="username" class="block text-sm font-medium text-gray-700">
                   Name
                </label>
                <div class="mt-1 flex rounded-md shadow-sm">
                  <input @keyup="name = $event.target.value" :value="name" type="text" name="username" id="username" autocomplete="username" class=" px-3 flex-1 focus:ring-indigo-500 border py-3 focus:border-indigo-500 block w-full min-w-0  rounded-md sm:text-sm border-gray-300" />
                </div>
              </div>

              <div class="sm:col-span-6">
                <label for="email" class="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <div class="mt-1 flex rounded-md shadow-sm">
                  <input @keyup="email = $event.target.value" :value="email" type="email" name="email" id="email" autocomplete="email" class=" px-3 flex-1 focus:ring-indigo-500 border py-3 focus:border-indigo-500 block w-full min-w-0  rounded-md sm:text-sm border-gray-300" />
                </div>
              </div>


              <div class="sm:col-span-6">
                <label for="password" class="block text-sm font-medium text-gray-700">
                  Password
                </label>
                <div class="mt-1 flex rounded-md shadow-sm">
                  <input @keyup="password = $event.target.value" :value="password" type="password" name="password" id="password" class=" px-3 flex-1 focus:ring-indigo-500 border py-3 focus:border-indigo-500 block w-full min-w-0  rounded-md sm:text-sm border-gray-300" />
                </div>
              </div>


              <div class="sm:col-span-6">
                <label for="password_confirmation" class="block text-sm font-medium text-gray-700">
                  Password Confirmation
                </label>
                <div class="mt-1 flex rounded-md shadow-sm">
                  <input @keyup="password_confirmation = $event.target.value" :value="password_confirmation" type="password" name="password_confirmation" id="password_confirmation" class=" px-3 flex-1 focus:ring-indigo-500 border py-3 focus:border-indigo-500 block w-full min-w-0  rounded-md sm:text-sm border-gray-300" />
                </div>
              </div>

              <div class="sm:col-span-6">
                <label for="designation" class="block text-sm font-medium text-gray-700">
                  Designation
                </label>
                <div class="mt-1">
                  <select @change="user_designation = $event.target.value" :value="user_designation" id="designation" name="designation" autocomplete="designation" class=" px-3 shadow-sm focus:ring-indigo-500  py-3 border px-2 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
                    <option>Other</option>
                    <option>Admin</option>
                  </select>
                </div>
              </div>

              <div class="flex justify-center sm:col-span-6">
                <button @click.prevent="registerUser" class="block bg-blue-600 hover:bg-blue-500 p-2 rounded-lg w-1/2 mx-auto text-white mt-4 text-center px-40 text-center mx-auto">Save</button>

              </div>

            </div>
          </div>


        </div>
      </form>
    </div>
  </div>
</template>

<script>
import user from '@/store/User'
import {ArrowLeftIcon} from '@heroicons/vue/solid'
export default {
  name: "DashboardAddUser",
  components:{ArrowLeftIcon},
  setup(){
    const {registerUser, name,email,password,password_confirmation,user_designation,createdById,loggedInUser} = user;
    createdById.value = loggedInUser.value.id;

    const test = (ev) => {
      // console.log(ev.target.value);
    }
    return {registerUser, name,email,password,password_confirmation,user_designation,createdById,test}
  }
};
</script>

<style scoped>

</style>