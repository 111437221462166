<template>
  <div class="mt-8">
    <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
      <h2 class="text-lg leading-6 font-medium text-gray-900">Overview</h2>
      <div class="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        <!-- Card -->
        <div v-for="card in cards" :key="card.name" class="bg-white overflow-hidden shadow rounded-lg">
          <div class="p-5">
            <div class="flex items-center">
              <div class="flex-shrink-0">
                <component :is="card.icon" class="h-6 w-6 text-gray-400" aria-hidden="true" />
              </div>
              <div class="ml-5 w-0 flex-1">
                <dl>
                  <dt class="text-sm font-medium text-gray-500 truncate">
                    {{ card.name }}
                  </dt>
                  <dd>
                    <div class="text-lg font-medium text-gray-900">
                      {{ format(card.amount) }}
                    </div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-5 py-3">
            <div class="text-sm">
              <router-link :to="{name:card.href}" class="font-medium text-blue-700 hover:text-blue-900">
                View all
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h2 class="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
      Recent activity
    </h2>

    <!-- Activity list (smallest breakpoint only) -->
    <div class="mt-12 max-w-6xl mx-auto">
    <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg mt-5">
      <table class="min-w-full divide-y divide-gray-200">
        <thead class="bg-gray-50">
        <tr>
          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Date
          </th>
          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Name
          </th>

          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Organisation
          </th>
          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Project
          </th>
          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Amount
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(donation, donationIdx) in transactions" :key="donation.email" :class="donationIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'">
          <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            {{ donation.date }}
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ donation.donor_name }}
          </td>

          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ donation.donor_organization }}
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ donation.project_name }}
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            {{ format(donation.donated_amount) }}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    </div>
    <!-- Activity table (small breakpoint and up) -->
  </div>
</template>

<script>

import { CashIcon,UsersIcon } from "@heroicons/vue/solid";
import { ScaleIcon, ViewGridIcon } from "@heroicons/vue/outline";
import home from "@/store/Home";
import { onMounted,ref,watch } from "vue";
import money from "@/helpers/money";

const statusStyles = {
  success: 'bg-green-100 text-green-800',
  processing: 'bg-yellow-100 text-yellow-800',
  failed: 'bg-gray-100 text-gray-800',
}
export default {
  name: "DashboardHome",
  components:{},
  setup(){
    const cards = ref( [])
    const {loadHome,homeDetails,total_donation_amount,total_donors,total_projects,transactions} = home;
    const {format} = money

    watch(homeDetails,(val) => {
       cards.value =  [
        { name: 'Total Donation Amount (Last 30 days)', href: 'dashboard-donations', icon: CashIcon, amount: total_donation_amount },
        { name: 'Number of Projects (Last 30 days)', href: 'dashboard-projects', icon: ViewGridIcon, amount: total_projects },
        { name: 'Number of Donors (Last 30 days)', href: 'dashboard-donations', icon: UsersIcon, amount: total_donors },
      ]
    })
    onMounted(()=>{
      homeDetails.value = false;
      loadHome();
    })

    return {cards,statusStyles,transactions,homeDetails,format}
  }
};
</script>

<style scoped>

</style>