import { ref } from "vue";
import toast from "@/helpers/toast";
import FundraisingHost from '@/helpers/FundraisingHost'

const name = ref();
const email = ref();
const password = ref();
const password_confirmation = ref();
const user_designation = ref('');
const createdById = ref('');
import {useLoading} from 'vue-loading-overlay'
const $loading = useLoading();
const loggedInUser = ref();
const userList = ref(<any[]>[])
const {success,error} = toast;

const resetUser = () => {
  name.value = null
  password.value = null
  email.value = null
  password_confirmation.value = null
  user_designation.value = '';
}


const registerUser = async () => {
  const loader = $loading.show({
    color: "#2581ef",
    opacity: .7,
  });
  const user = {
    'name': name.value,
    'email': email.value,
    'password': password.value,
    'password_confirmation': password_confirmation.value,
    'user_designation': user_designation.value.toString(),
    'created_by_id':createdById.value.toString()
  }

const  res = await fetch(FundraisingHost.url+'register',{
    method:'post',
    body: JSON.stringify(user),
    headers:{
      'Content-Type': 'application/json',
      'Accept':'application/json, text/plain',
      'Authorization' : 'Bearer '+localStorage.getItem('token')
    }
  });

  const data =  await res.json();
  if (data.status === 'success'){
    resetUser();
    console.log('user registered');
    success(data.message);
  }else{
   error(data.message);
  }
  loader.hide();

}


const loginUser = async () => {
  const loader = $loading.show({
    color: "#2581ef",
    opacity: .7,
  });
  const user =  {
    'email':email.value,
    'password':password.value,
  }
  const res = await fetch(FundraisingHost.url+'login',{
    method:'post',
    body: JSON.stringify(user),
    headers:{
      'Content-Type': 'application/json',
      'Accept':'application/json, text/plain'
    }
  });

  const data =  await res.json();
  if (data.status === 'success'){
    resetUser();
    loggedInUser.value = data.user;
    // token.value  = data.token;
    localStorage.setItem('token', data.token);
    localStorage.setItem('user', JSON.stringify(data.user));
    success(data.message)
  }else{
   error(data.message)
  }
  loader.hide();
}


const logoutUser = async () => {
  const loader = $loading.show({
    color: "#2581ef",
    opacity: .7,
  });
  const res = await fetch(`${FundraisingHost.url}logout` ,{
    method:'post',
    headers:{
      'Content-Type': 'application/json',
      'Accept':'application/json, text/plain',
      'Authorization' : 'Bearer '+localStorage.getItem('token')
    }
  });

  const data =  await res.json();
  if (data.status === 'success'){
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    window.location.href = '/';
    success(data.message)
  }else{
     error(data.message)
    if (res.status === 401){
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      window.location.href = '/';
    }
  }
  loader.hide();
  return true;
}

const loadUsers = async () => {
  const loader = $loading.show({
    color: "#2581ef",
    opacity: .7,
  });
  const res = await fetch(`${FundraisingHost.url}get-user-list` ,{
    headers:{
      'Content-Type': 'application/json',
      'Accept':'application/json, text/plain',
      'Authorization' : 'Bearer '+localStorage.getItem('token')
    }
  });

  const data =  await res.json();
  if (data.status === 'success') {
    userList.value = data.users;
    success(data.message)
  }else{
    error(data.message)
  }
  loader.hide();
}

const deleteUser = async (user_id: any) => {
  const loader = $loading.show({
    color: "#2581ef",
    opacity: .7,
  });
  const res = await fetch(`${FundraisingHost.url}delete-user/${user_id}` ,{
    method:'PATCH',
    headers:{
      'Content-Type': 'application/json',
      'Accept':'application/json, text/plain',
      'Authorization' : 'Bearer '+localStorage.getItem('token')
    }
  });

  const data =  await res.json();
  if (data.status === 'success') {
    success(data.message)
    loadUsers();
  }else{
    error(data.message)
  }
  loader.hide();
}


const updateUser = async () => {
  const loader = $loading.show({
    color: "#2581ef",
    opacity: .7,
  });

  const user = <any> {
    'name': name.value || loggedInUser.value.name,
    'email': email.value || loggedInUser.value.email,
    'user_designation': user_designation.value.toString() || loggedInUser.value.user_designation.toString(),
  }

  if (password.value && password_confirmation.value){
    user['password']  = password.value;
    user['password_confirmation'] = password_confirmation.value;
  }

  const res = await fetch(`${FundraisingHost.url}update-user/${loggedInUser.value.id}` ,{
    method:'PATCH',
    body: JSON.stringify(user),
    headers:{
      'Content-Type': 'application/json',
      'Accept':'application/json, text/plain',
      'Authorization' : 'Bearer '+localStorage.getItem('token')
    }
  });

  const data =  await res.json();
  if (data.status === 'success') {
    success(data.message)
  }else{
    error(data.message)
  }
  loader.hide();
}



export default {registerUser,loginUser, name,email,password,password_confirmation,user_designation,createdById,loggedInUser,logoutUser,loadUsers,userList,deleteUser,updateUser}