import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import DonationPage from "../views/DonationPage.vue";
import Dashboard from "../views/Dashboard.vue";
import Projects from "../views/Projects.vue";
import Checkout from "../views/Checkout.vue";
import FAQ from "../views/FAQ.vue";
import Status  from "../views/Status.vue";
import DashboardHome from "../components/DashboardHome.vue";
import DashboardProjects from "../components/DashboardProjects.vue";
import DashboardAddProject from "../components/DashboardAddProject.vue";
import DashboardDonations from "../components/DashboardDonations.vue";
import DashboardUsers from "../components/DashboardUsers.vue";
import DashboardAddUser from "../components/DashboardAddUser.vue";
import DashboardTables from "../components/DashboardTables.vue";
import Resources from "../components/DashboardResources.vue";
import DashboardAddResource from "../components/DashboardAddResource.vue";
import DashboardAddDonation from "../components/DashboardAddDonation.vue";
import DashboardAddTables from "../components/DashboardAddTables.vue";
import DashboardSettings from "../components/DashboardSettings.vue";
import DashboardCollections from "../components/DashboardCollections.vue";
import DashboardAddCollection from "../components/DashboardAddCollection.vue";
import DashboardEditTable from "../components/DashboardEditTable.vue";
import DashboardAddTemplateData from "../components/DashboardAddTemplateData.vue";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/show/:id?",
    name: "donation",
    component: DonationPage,
  },

  {
    path: "/projects",
    name: "projects",
    component: Projects,
  },
  {
    path: "/checkout",
    name: "checkout",
    component: Checkout,
  },
  {
    path: "/faq",
    name: "faq",
    component: FAQ,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    children:[
      {
        path: 'home',
        name:'dashboard-home',
        component:DashboardHome
      },
      {
        path: 'projects',
        name:'dashboard-projects',
        component:DashboardProjects
      },
      {
        path: 'add-projects',
        name:'dashboard-add-projects',
        component:DashboardAddProject
      },

      {
        path: 'donations',
        name:'dashboard-donations',
        component:DashboardDonations
      },

      {
        path: 'add-donations',
        name:'dashboard-add-donations',
        component:DashboardAddDonation
      },

      {
        path: 'users',
        name:'dashboard-users',
        component:DashboardUsers
      },
      {
        path: 'add-users',
        name:'dashboard-add-users',
        component:DashboardAddUser
      },
      {
        path: 'tables',
        name:'dashboard-tables',
        component:DashboardTables
      },
      {
        path: 'add-tables',
        name:'dashboard-add-tables',
        component:DashboardAddTables
      },
      {
        path: 'edit-tables',
        name:'dashboard-edit-tables',
        component:DashboardEditTable
      },
      {
        path: 'data-tables',
        name:'dashboard-data-tables',
        component:DashboardAddTemplateData
      },
      {
        path: 'resources',
        name:'dashboard-resources',
        component:Resources
      },
      {
        path: 'add-resources',
        name:'dashboard-add-resources',
        component:DashboardAddResource
      },
      {
        path: 'settings',
        name:'dashboard-settings',
        component:DashboardSettings
      },
      {
        path: 'collections',
        name:'dashboard-collections',
        component:DashboardCollections
      },
      {
        path: 'add-collections',
        name:'dashboard-add-collections',
        component:DashboardAddCollection
      }
    ]
  },

  {
    path: "/payment_confirmation",
    name: "payment_status",
    component: Status,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
