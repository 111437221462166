<template>
  <div class="mt-8">
    <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
      <button @click.prevent="$router.back()" class="capitalize flex text-blue-500 hover:underline">
        <ArrowLeftIcon class="mr-2 h-6 w-6" />
        back
      </button>
      <div class="flex justify-center items-center">
        <h2 class="text-xl leading-6 font-medium text-gray-900">Add Collection</h2>
      </div>
    </div>
    <div class="mt-12 max-w-2xl mx-auto">
      <form enctype="application/x-www-form-urlencoded" method="post" id="create-collection">
        <input v-if="loggedInUser" type="hidden" name="created_by_id" :value="loggedInUser.id">
        <div class="space-y-8 divide-y divide-gray-200">
          <div>

            <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div class="sm:col-span-6">
                <label for="equipment_name" class="block text-sm font-medium text-gray-700">
                  Equipment Name
                </label>
                <div class="mt-1 flex rounded-md shadow-sm">
                  <input type="text" name="equipment_name" id="equipment_name" autocomplete="equipment_name" class="flex-1 px-3  focus:ring-indigo-500 border py-3 focus:border-indigo-500 block w-full min-w-0  rounded-md sm:text-sm border-gray-300" />
                </div>
              </div>

              <div class="sm:col-span-6">
                <label for="facility_name" class="block text-sm font-medium text-gray-700">
                  Facility Name
                </label>
                <div class="mt-1 flex rounded-md shadow-sm">
                  <input type="text" name="facility_name" id="facility_name" autocomplete="facility_name" class="flex-1 px-3  focus:ring-indigo-500 border py-3 focus:border-indigo-500 block w-full min-w-0  rounded-md sm:text-sm border-gray-300" />
                </div>
              </div>
              <div class="sm:col-span-6">
                <label for="service_fee" class="block text-sm font-medium text-gray-700">
                  Service Fee
                </label>
                <div class="mt-1 flex rounded-md shadow-sm">
                  <input type="text" name="service_fee" id="service_fee" autocomplete="service_fee" class="flex-1 px-3  focus:ring-indigo-500 border py-3 focus:border-indigo-500 block w-full min-w-0  rounded-md sm:text-sm border-gray-300" />
                </div>
              </div>

              <div class="sm:col-span-6">
                <label for="date_received" class="block text-sm font-medium text-gray-700">
                  date_received
                </label>
                <div class="mt-1 flex rounded-md shadow-sm">
                  <input type="date" name="date_received" id="date_received" autocomplete="date_received" class="flex-1 px-3  focus:ring-indigo-500 border py-3 focus:border-indigo-500 block w-full min-w-0  rounded-md sm:text-sm border-gray-300" />
                </div>
              </div>
              <div class="sm:col-span-6">
                <label for="project_id" class="block text-sm font-medium text-gray-700">
                  Project
                </label>
                <div class="mt-1">
                  <select name="project_id"  class="shadow-sm focus:ring-indigo-500  py-3 border px-2 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
                    <option>Choose Project</option>
                    <option v-for="project in projectList" :key="project.id" :value="project.id">{{project.project_name}}</option>
                  </select>
                </div>
              </div>

              <div class="flex justify-center sm:col-span-6">
                <button @click.prevent="create(loggedInUser.id)" class="block bg-blue-600 hover:bg-blue-500 p-2 rounded-lg w-1/2 mx-auto text-white mt-4 text-center px-40 text-center mx-auto">Save</button>

              </div>

            </div>
          </div>


        </div>
      </form>
    </div>
  </div>
</template>

<script>
import collection from "@/store/Collection";
import project from "@/store/Project";
import { onMounted } from "vue";
import user from "@/store/User";
import {ArrowLeftIcon} from '@heroicons/vue/solid'

export default {
  components:{ArrowLeftIcon},
  setup(){
    const {loadProjects,projectList} = project;
    const {loggedInUser} = user

    const {create} = collection
    onMounted(()=>{
      if (loggedInUser.value){
        loadProjects(loggedInUser.value.id);
      }
    })
    return{
      create,
      projectList,
      loggedInUser
    }
  }
};
</script>

<style scoped>

</style>