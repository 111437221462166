<template>
  <div class="mt-8">
    <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex justify-between items-center">
        <h2 class="text-xl leading-6 font-medium text-gray-900">Donations</h2>
        <div class="relative z-0 flex-1 px-2 flex items-center justify-center sm:inset-0 my-10">
          <div class="w-full max-w-xl mx-auto">
            <label for="search" class="sr-only">Search</label>
            <div class="relative">
              <div class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <input id="search" v-model="filter" name="search" class="block w-full bg-gray-100 border border-transparent rounded-md py-3 pl-10 pr-3 text-sm placeholder-gray-400 focus:outline-none focus:bg-gray-50 focus:border-white focus:ring-white focus:text-gray-900 focus:placeholder-gray-500 sm:text-sm" placeholder="Search" type="search" />
            </div>
          </div>
        </div>
        <router-link  :to="{name:'dashboard-add-donations'}"  class="block bg-blue-600 hover:bg-blue-500 p-2 rounded-lg  px-5 text-white  text-center">Create</router-link>

      </div>
    </div>
    <div class="mt-12 max-w-6xl mx-auto">
      <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50">
          <tr>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Date
            </th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Name
            </th>

            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Organisation
            </th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Project
            </th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Amount
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(donation, donationIdx) in getDonationList" :key="donation.email" :class="donationIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'">
            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              {{ donation.date }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {{ donation.donor_name }}
            </td>

            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {{ donation.donor_organization }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {{ donation.project_name }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {{ format(donation.donated_amount) }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { SearchIcon } from '@heroicons/vue/solid'
import { onMounted } from "vue";
import user from "@/store/User";
import project from "@/store/Project";
import donations from "@/store/Donations";
import money from "@/helpers/money";
export default {
  name: "DashboardDonations",
  components:{SearchIcon},
  setup(){
    const {donationList,loadDonations,getDonationList,filter} = donations;
    const {loggedInUser} = user;
    const {format} = money
    const {loadProjects,projectList} = project;
    onMounted(() => {
      if (loggedInUser.value){
        loadDonations(loggedInUser.value.id);

      }
    })
    return {donationList,projectList,getDonationList,filter,format}
  }
};
</script>

<style scoped>

</style>