import { ref } from "vue";
import toast from "@/helpers/toast";
import {useLoading} from 'vue-loading-overlay'
import FundraisingHost from '@/helpers/FundraisingHost'

const $loading = useLoading();
const templateList = ref(<any[]>[])
const {success,error} = toast;
const templateName = ref();
const projectId = ref('');
const createdById = ref('');
const accountability = ref();
const fields = ref([
  {type:null, name:null}
]);
const dataFields = ref([]);
const selectedTemplate = ref();


const create = async () => {
  const loader = $loading.show({
    color: "#2581ef",
    opacity: .7,
  });
  const templateObj = {
    'template_name' : templateName.value,
    'template_project_id' :  projectId.value.toString(),
    'template_fields' : fields.value,
    'created_by_id' : createdById.value.toString()

};
  const f = document.getElementById('create-template');
  const  res = await fetch(FundraisingHost.url+'create-project-template',{
    method:'post',
    body: JSON.stringify(templateObj),
    headers:{
      'Content-Type': 'application/json',
      'Accept':'application/json, text/plain',
      'Authorization' : 'Bearer '+localStorage.getItem('token')
    }
  });

  const data =  await res.json();
  if (data.status === 'success'){
    if (f){
      // @ts-ignore
      f.reset();
    }
    success(data.message);
  }else{
    error(data.message);
  }
  loader.hide();

}


const update = async (user_id:any) => {
  const loader = $loading.show({
    color: "#2581ef",
    opacity: .7,
  });

  const templateObj = {
    'template_name' : templateName.value,
    'template_project_id' :  projectId.value.toString(),
    'template_fields' : fields.value,
    'created_by_id' : createdById.value.toString()

  };

  const  res = await fetch(`${FundraisingHost.url}update-project-template/${selectedTemplate.value.id}`,{
    method:'PATCH',
    body: JSON.stringify(templateObj),
    headers:{
      'Content-Type': 'application/json',
      'Accept':'application/json, text/plain',
      'Authorization' : 'Bearer '+localStorage.getItem('token')
    }
  });

  const data =  await res.json();
  if (data.status === 'success'){
    success(data.message);
    loadTemplate(user_id)
  }else{
    error(data.message);
  }
  loader.hide();

}

const loadTemplate = async (user_id = 0) => {
  const loader = $loading.show({
    color: "#2581ef",
    opacity: .7,
  });
  const res = await fetch(`${FundraisingHost.url}get-template-list/${user_id}` ,{
    headers:{
      'Content-Type': 'application/json',
      'Accept':'application/json, text/plain',
      'Authorization' : 'Bearer '+localStorage.getItem('token')
    }
  });

  const data =  await res.json();
  if (data.status === 'success') {
    templateList.value = data.templates;
    // success(data.message)
  }else{
    error(data.message)
  }
  loader.hide();
}

const loadProjectResources = async (project_id = 0) => {
  const loader = $loading.show({
    color: "#2581ef",
    opacity: .7,
  });
  const res = await fetch(`${FundraisingHost.url}get-project-resources-list/${project_id}` ,{
    headers:{
      'Content-Type': 'application/json',
      'Accept':'application/json, text/plain',
      'Authorization' : 'Bearer '+localStorage.getItem('token')
    }
  });

  const data =  await res.json();
  if (data.status === 'success') {
    templateList.value = data.resources;
    // success(data.message)
  }else{
    error(data.message)
  }
  loader.hide();
}



const loadProjectAccountability = async (project_id = 0) => {
  const loader = $loading.show({
    color: "#2581ef",
    opacity: .7,
  });
  const res = await fetch(`${FundraisingHost.url}get-project-accountability/${project_id}` ,{
    headers:{
      'Content-Type': 'application/json',
      'Accept':'application/json, text/plain',
      'Authorization' : 'Bearer '+localStorage.getItem('token')
    }
  });

  const data =  await res.json();
  if (data.status === 'success') {
    accountability.value = data.accountability;
    // success(data.message)
  }else{
    error(data.message)
  }
  loader.hide();
}



const deleteTemplate = async (user_id: any) => {
  const loader = $loading.show({
    color: "#2581ef",
    opacity: .7,
  });
  const res = await fetch(`${FundraisingHost.url}delete-project-template/${selectedTemplate.value.id}` ,{
    method:'PATCH',
    headers:{
      'Content-Type': 'application/json',
      'Accept':'application/json, text/plain',
      'Authorization' : 'Bearer '+localStorage.getItem('token')
    }
  });

  const data =  await res.json();
  if (data.status === 'success') {
    success(data.message)
    loadTemplate(user_id);
  }else{
    error(data.message)
  }
  loader.hide();
}

const addFields = () => {
  fields.value.push( {type:null, name:null})
}

const removeField = (index: any) => {
  fields.value.splice(index, 1); // 2nd parameter means remove one item only
}

const deleteColumn = async (column_name:any, user_id:any, index:any) => {
  const res = await fetch(`${FundraisingHost.url}delete-project-template-column/${selectedTemplate.value.id}/${column_name}/${user_id}` ,{
    method:'PATCH',
    headers:{
      'Content-Type': 'application/json',
      'Accept':'application/json, text/plain',
      'Authorization' : 'Bearer '+localStorage.getItem('token')
    }
  });

  const data =  await res.json();
  if (data.status === 'success') {
    success(data.message)
    removeField(index);
  }else{
    error(data.message)
  }
}



const addTemplateData = async () => {
  const loader = $loading.show({
    color: "#2581ef",
    opacity: .7,
  });
  const templateObj = {
     data : dataFields.value,
    template_id :  selectedTemplate.value.id.toString(),
  };
  const  res = await fetch(FundraisingHost.url+'add-data-to-template',{
    method:'post',
    body: JSON.stringify(templateObj),
    headers:{
      'Content-Type': 'application/json',
      'Accept':'application/json, text/plain',
      'Authorization' : 'Bearer '+localStorage.getItem('token')
    }
  });

  const data =  await res.json();
  if (data.status === 'success'){
    success(data.message);
  }else{
    error(data.message);
  }
  loader.hide();

}




export default {loadTemplate,templateList,create,update,deleteTemplate,loadProjectResources,
  fields,addFields,templateName,projectId,createdById,selectedTemplate,removeField,deleteColumn,dataFields,addTemplateData,accountability,loadProjectAccountability}