<template>
  <div class="">
    <div class="mx-auto py-16 px-4 sm:px-6 lg:px-8">
      <div class="relative  sm:px-6">
        <div class="relative max-w-7xl mx-auto">
          <div class="text-center">
            <h2 class="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
              All Projects
            </h2>

            <div class="relative z-0 flex-1 px-2 flex items-center justify-center sm:inset-0 my-10">
              <div class="w-full max-w-xl mx-auto">
                <label for="search" class="sr-only">Search</label>
                <div class="relative">
                  <div class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                    <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                  <input id="search" v-model="filter" name="search" class="block w-full bg-gray-100 border border-transparent rounded-md py-3 pl-10 pr-3 text-sm placeholder-gray-400 focus:outline-none focus:bg-gray-50 focus:border-white focus:ring-white focus:text-gray-900 focus:placeholder-gray-500 sm:text-sm" placeholder="Search" type="search" />
                </div>
              </div>
            </div>

          </div>
          <div class="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none pb-20">
            <div v-for="project in getProjectList" :key="project.project_name" class="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <div class="flex-shrink-0">
                <img class="h-48 w-full object-cover" :src="FundraisingHost.url +'get-image/'+project.project_photo_file_path" :alt="project.project_name" />
              </div>
              <div class="flex-1 bg-white p-6  flex flex-col justify-between">
                <div class="flex-1">
                  <a @click.prevent="selectedProject = project; $router.push({name:'donation',params:{id:project.id}})" href="#" class="block">
                    <p class="text-xl font-semibold text-gray-900 hover:underline">
                      {{ project.project_name }}
                    </p>
                    <p class="mt-3 text-base text-gray-500">
                      {{ project.project_summary }}
                    </p>
                  </a>
                </div>
                <div class="mt-6  items-center font-extrabold text-black">
                  <div class="w-full bg-gray-50 rounded-full dark:bg-gray-100">
                    <div class="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full" :style="'width:'+(project.raisedAmount/project.project_target_amount)*100+'%'"> {{(project.raisedAmount/project.project_target_amount)*100}}%</div>
                  </div>
                  <div class="flex mt-5 justify-between">
                    <span><span class="uppercase">{{project.project_target_currency}}</span> {{project.raisedAmount}} Raised</span>
                    <span> {{project.totalDonors}}  Donations</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SearchIcon } from '@heroicons/vue/solid'
import project from "@/store/Project";
import { onMounted } from "vue";
import FundraisingHost from '@/helpers/FundraisingHost'

export default {
  name: "Projects",
  components:{SearchIcon},
  setup() {
    const {loadProjects,getProjectList,filter,selectedProject} = project;
    onMounted(() => {
      loadProjects('Doq1dA4wl5');
    })
    return{getProjectList,filter,selectedProject, FundraisingHost}
  }
};
</script>

<style scoped>

</style>