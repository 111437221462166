<template>
  <div v-if="selectedProject" class="relative bg-white py-16 sm:py-24">
    <h2 class="text-3xl text-gray-900 font-extrabold tracking-tight sm:text-4xl mb-10 text-center">
     {{selectedProject.project_name}}
    </h2>
    <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
      <div class="relative sm:py-16 lg:py-0">
        <div aria-hidden="true" class="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
          <div class="absolute inset-y-0 right-1/2 w-full bg-gray-50 rounded-r-3xl lg:right-72" />
          <svg class="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12" width="404" height="392"
               fill="none" viewBox="0 0 404 392">
            <defs>
              <pattern id="02f20b47-fd69-4224-a62a-4c9de5c763f7" x="0" y="0" width="20" height="20"
                       patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width="404" height="392" fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)" />
          </svg>
        </div>
        <div class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
          <!-- Testimonial card-->
          <div class="relative pt-64 pb-10 rounded-2xl shadow-xl overflow-hidden">
            <img class="absolute inset-0 h-full w-full object-cover" loading="lazy"  
                 :src="FundraisingHost.url + 'get-image/'+selectedProject.project_photo_file_path" :alt="selectedProject.project_name" />
            <div class="relative px-8">

              <blockquote class="mt-20">

              </blockquote>
            </div>
          </div>
        </div>
      </div>

      <div class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 w-full">
        <div class="mt-10">
          <dl class="grid grid-cols-2 gap-x-4 gap-y-8">
            <div v-for="stat in stats" :key="stat.label" class=" pt-6">
              <dt class="text-base font-medium text-gray-500">{{ stat.label }}</dt>
              <dd class="text-3xl font-extrabold tracking-tight text-gray-900 uppercase">{{ stat.value }}</dd>
            </div>
          </dl>
          <div class="w-full bg-gray-50 rounded-full dark:bg-gray-100 mt-10 h-6">
            <div class="bg-blue-600 text-xs font-medium text-blue-100 text-center h-6 p-0.5 leading-none rounded-full"
                 :style="'width:'+(selectedProject.raisedAmount/selectedProject.project_target_amount)*100+'%'"></div>
          </div>

          <div class="mt-6">
            <div class="mt-1">
              <input type="number"  v-model="donation"  name="" placeholder="Add Your Donation Here" class="block w-full border-blue-300 p-4 bg-gray-50 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm" />
            </div>
          </div>

        <div class="flex justify-center sm:my-0 my-3 sm:pb-0 pb-2">
          <button v-if="!donation" disabled class="bg-blue-200 text-white mt-3 mx-auto px-20 text-2xl font-bold py-4 shadow rounded-lg ">Donate</button>
          <router-link v-else :to="{name:'checkout'}"  class="bg-blue-600 text-white mt-3 mx-auto px-20 text-2xl font-bold py-4 shadow rounded-lg mt">Donate ({{format(donation)}})</router-link>
        </div>
        </div>
      </div>
    </div>

    <div class="lg:mx-auto lg:max-w-7xl">
      <div class="mx-3 sm:hidden sm:mx-0">
        <label for="tabs" class="sr-only">Select a tab</label>
        <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
        <select @change="current = $event.target.value" id="tabs" name="tabs" class="block w-full focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md">
          <option v-for="tab in tabs" :key="tab.name"  :value="tab.name" :selected="tab.current">{{ tab.name }}</option>
        </select>
      </div>
      <div class="hidden sm:block">
        <div class="border-b border-gray-200">
          <nav class="-mb-px flex" aria-label="Tabs">
            <button v-for="tab in tabs" @click="current = tab.name" :key="tab.name" :href="tab.href" :class="[tab.name == current ? 'border-blue-500 text-blue-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300', 'w-1/4 py-4 px-1 text-center border-b-2 font-medium ']" :aria-current="tab.current ? 'page' : undefined">
              {{ tab.name }}
            </button>
          </nav>
        </div>
      </div>
      <div>
        <div class="relative py-16 bg-white overflow-hidden">
          <div class="relative px-4 sm:px-6 lg:px-8 text-xl blog" v-if="current == 'Description'">
            <div class="text-lg max-w-prose mx-auto" v-html="selectedProject.project_description" ></div>
          </div>
              <!--Show table-->

          <div class="flex flex-col" v-if="current == 'Donations'">
            <div class="flex -mt-10 mb-5">
              <div style="width: 150px">
                <select name="year" v-model="selectedYear"   autocomplete="country-name" class="shadow-sm focus:ring-indigo-500   py-3 border px-2 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
                  <option>Select year</option>
                  <option v-for="(year,index) in years" :key="index" :value="year">{{year}}</option>
                </select>
              </div>

              <div class="flex-1 ml-2 flex items-center overflow-x-auto">
                <button @click="selectedMonth = index"  :class="[index == selectedMonth ? 'border-blue-500' : '' , 'border py-2 px-5 bg-white capitalize rounded hover:bg-gray-50 mx-2']" v-for="(month,index) in months" :key="index" >{{month}}</button>
              </div>
            </div>

            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-50">
                    <tr>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Date
                      </th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Name
                      </th>

                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Organisation
                      </th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Amount
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(donation, donationIdx) in donationList" :key="donation.email" :class="donationIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'">
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        {{ donation.date }}
                      </th>
                      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {{ donation.donor_name }}
                      </td>

                      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {{ donation.donor_organization }}
                      </td>

                      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {{ format(donation.donated_amount) }}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>


          <div class="flex flex-col" v-if="current == 'Accountability'">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table class="min-w-full divide-y divide-gray-200" v-if="accountability && accountability.length > 0">
                    <thead class="bg-gray-50">
                    <tr>
                      <th v-for="(row,index) in accountability[0].report_columns" :key="index" scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        {{row}}
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td v-for="(val, index) in values" :key="index" :class="index % 2 === 0 ? 'bg-white' : 'bg-gray-50'" class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {{ val }}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
      <!-- Add download resources -->
          <div class="bg-white  overflow-hidden sm:rounded-lg" v-if="current == 'Resources'">
            <div class=" mx-auto   px-4 py-5 sm:p-0 rounded">
              <ul role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                <li  v-for="resource in resourceList" :key="resource.id" class="col-span-1 bg-white border rounded-lg shadow divide-y divide-gray-200">
                  <div class="w-full flex items-center justify-between p-6 space-x-6">
                    <div class="flex-1 truncate">
                      <div class="flex items-center space-x-3">
                        <h3 class="text-gray-900  font-medium truncate">{{resource.resource_name}}</h3>
                      </div>
                    </div>
                    <div class="h-10 w-10 rounded-full bg-gray-50 flex justify-center items-center text-blue-500 font-bold text-lg">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-medical" viewBox="0 0 16 16">
                        <path d="M7.5 5.5a.5.5 0 0 0-1 0v.634l-.549-.317a.5.5 0 1 0-.5.866L6 7l-.549.317a.5.5 0 1 0 .5.866l.549-.317V8.5a.5.5 0 1 0 1 0v-.634l.549.317a.5.5 0 1 0 .5-.866L8 7l.549-.317a.5.5 0 1 0-.5-.866l-.549.317V5.5zm-2 4.5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 2a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5z"/>
                        <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
                      </svg>
                    </div>

                  </div>
                  <div>
                    <div class="-mt-px">
                      <div class=" flex-1 flex justify-center">
                        <a :href="FundraisingHost.url + 'download-resource/'+resource.id" class="font-medium w-full text-blue-600 hover:text-blue-500 text-center p-3 ">
                          Download
                        </a>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>

            </div>
          </div>


<!--          collections-->

          <div class="flex flex-col" v-if="current == 'Collections'">
            <div class="flex -mt-10 mb-5">
              <div style="width: 150px">
                <select name="year" v-model="selectedYear"   autocomplete="country-name" class="shadow-sm focus:ring-indigo-500   py-3 border px-2 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
                  <option>Select year</option>
                  <option v-for="(year,index) in years" :key="index" :value="year">{{year}}</option>
                </select>
              </div>

              <div class="flex-1 ml-2 flex items-center overflow-x-auto">
                <button @click="selectedMonth = index"  :class="[index == selectedMonth ? 'border-blue-500' : '' , 'border py-2 px-5 bg-white capitalize rounded hover:bg-gray-50 mx-2']" v-for="(month,index) in months" :key="index" >{{month}}</button>
              </div>
            </div>
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-50">
                    <tr>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Date
                      </th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Equipment
                      </th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Facility
                      </th>

                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Service Fee
                      </th>

                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(collection, personIdx) in collectionList " :key="personIdx" :class="personIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'">
                      <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {{ collection.date_received }}
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {{ collection.equipment_name }}
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {{ collection.facility_name }}
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {{ format(collection.service_fee) }}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>





    </div>
  </div>
</template>

<script>
import { ref,onMounted,watch } from "vue";
import project from "@/store/Project";
import donations from "@/store/Donations";
import resource from "@/store/Resource";
import checkout from "@/store/Checkout";
import collection from "@/store/Collection";
import template from "@/store/Template";
import dates from "@/helpers/dates";
import money from "@/helpers/money";
import {useRoute} from 'vue-router'
import FundraisingHost from '@/helpers/FundraisingHost'

export default {
  name: "DonationPage",
  setup() {
    const tabs = ref([
      { name: 'Description', href: '#', current: false },
      { name: 'Donations', href: '#', current: false },
      { name: 'Accountability', href: '#', current: true },
      { name: 'Collections', href: '#', current: false },
      { name: 'Resources', href: '#', current: false },
    ]);
    const current = ref('Description');
   const name = ref('');
   const values  = ref([])
    const route = useRoute();
    const {selectedProject,getProjectById} = project;
    const {donationList,loadProjectDonations} = donations
    const {resourceList,loadProjectResources} = resource
    const {donation,selectedProjectId} = checkout;
    const {collectionList,loadProjectCollections} = collection
    const {accountability,loadProjectAccountability} = template;
    const {months,years} = dates;
    const selectedYear = ref( new Date().getFullYear());
    const selectedMonth = ref(new Date().getMonth());
    const {format} = money
    const stats = ref();

    if(!route.params.id || route.params.id == 'undefined' ){

      location.href = '/'

    }


    onMounted(() => {

      donation.value = null;

      if (!selectedProject.value){
        getProjectById(route.params.id);
      }else{
        stats.value = [
          { label: "Donors", value: selectedProject.value.totalDonors },
          { label: "Raised", value: selectedProject.value.project_target_currency +' '+format(selectedProject.value.raisedAmount) },
          { label: "Target", value:  selectedProject.value.project_target_currency +' '+format(selectedProject.value.project_target_amount)}
        ]
        selectedProjectId.value = selectedProject.value.id;
        loadProjectDonations(selectedProject.value.id,selectedYear.value,selectedMonth.value + 1)
        loadProjectResources(selectedProject.value.id)
        loadProjectCollections(selectedProject.value.id,selectedYear.value,selectedMonth.value + 1)
        loadProjectAccountability(selectedProject.value.id)
      }

    })

    watch(selectedProject, () =>{
      stats.value = [
        { label: "Donors", value: selectedProject.value.totalDonors },
        { label: "Raised", value: selectedProject.value.project_target_currency +' '+format(selectedProject.value.raisedAmount) },
        { label: "Target", value:  selectedProject.value.project_target_currency +' '+format(selectedProject.value.project_target_amount)}
      ]
      selectedProjectId.value = selectedProject.value.id;
      loadProjectDonations(selectedProject.value.id,selectedYear.value,selectedMonth.value + 1)
      loadProjectResources(selectedProject.value.id)
      loadProjectCollections(selectedProject.value.id,selectedYear.value,selectedMonth.value + 1)
      loadProjectAccountability(selectedProject.value.id)
    })

    watch(accountability, (val)=>{
      if (val.length) {
        name.value = val[0].report_name;
        const fields = val[0].report_columns;
        for (let i = 0; i < val[0][name.value].length; i++) {
          for (let x = 0; x < fields.length; x++) {
            values.value.push(val[0][name.value][i][fields[x]])
          }

        }
      }
    })

    watch([selectedYear,selectedMonth],([year,month]) => {
      loadProjectCollections(selectedProject.value.id,year,month +1);
      loadProjectDonations(selectedProject.value.id,year,month + 1);
    })

    return { stats,tabs,current,
      selectedProject,
      donationList,
      resourceList,donation,
      collectionList,
      accountability,values,
      months,years,selectedYear,
      selectedMonth,selectedProjectId,format,
      FundraisingHost
      };

  }
};
</script>

<style scoped>
.blog p{
   margin: 1rem 0;
}

.overflow-x-auto{
  padding-bottom: 1rem;
}
</style>