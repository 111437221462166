<template>
  <div class="mt-8">
    <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
      <button @click.prevent="$router.back()" class="capitalize flex text-blue-500 hover:underline">
        <ArrowLeftIcon class="mr-2 h-6 w-6" />
        back
      </button>
      <div class="flex justify-center items-center">
        <h2 class="text-xl leading-6 font-medium text-gray-900">Add Donation</h2>
      </div>
    </div>
    <div class="mt-12 max-w-2xl mx-auto">
      <form enctype="application/x-www-form-urlencoded" method="post" id="create-donations" >
        <input v-if="loggedInUser" type="hidden" name="created_by_id" :value="loggedInUser.id">
        <div class="space-y-8 divide-y divide-gray-200">
          <div>

            <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div class="sm:col-span-6">
                <label for="donor_name" class="block text-sm font-medium text-gray-700">
                  Donor Names
                </label>
                <div class="mt-1 flex rounded-md shadow-sm">
                  <input type="text" name="donor_name" id="donor_name" autocomplete="donor_name" class="flex-1 px-3  focus:ring-indigo-500 border py-3 focus:border-indigo-500 block w-full min-w-0  rounded-md sm:text-sm border-gray-300" />
                </div>
              </div>

              <div class="sm:col-span-6">
                <label for="donor_organization" class="block text-sm font-medium text-gray-700">
                  Donor Organisations
                </label>
                <div class="mt-1 flex rounded-md shadow-sm">
                  <input type="text" name="donor_organization" id="donor_organization" autocomplete="donor_organization" class="flex-1 px-3  focus:ring-indigo-500 border py-3 focus:border-indigo-500 block w-full min-w-0  rounded-md sm:text-sm border-gray-300" />
                </div>
              </div>

              <div class="sm:col-span-6">
                <label for="donor_email" class="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <div class="mt-1 flex rounded-md shadow-sm">
                  <input type="email" name="donor_email" id="donor_email" autocomplete="donor_email" class="flex-1 px-3  focus:ring-indigo-500 border py-3 focus:border-indigo-500 block w-full min-w-0  rounded-md sm:text-sm border-gray-300" />
                </div>
              </div>


              <div class="sm:col-span-6">
                <label for="donation_payment_method" class="block text-sm font-medium text-gray-700">
                  Donation Payment Method
                </label>
                <div class="mt-1 flex rounded-md shadow-sm">
                  <input type="text" name="donation_payment_method" id="donation_payment_method" autocomplete="donation_payment_method" class="flex-1 px-3  focus:ring-indigo-500 border py-3 focus:border-indigo-500 block w-full min-w-0  rounded-md sm:text-sm border-gray-300" />
                </div>
              </div>


              <div class="sm:col-span-6">
                <label for="donated_amount" class="block text-sm font-medium text-gray-700">
                  Donated Amount
                </label>
                <div class="mt-1 flex rounded-md shadow-sm">
                  <input type="text" name="donated_amount" id="donated_amount" autocomplete="donated_amount" class="flex-1 px-3  focus:ring-indigo-500 border py-3 focus:border-indigo-500 block w-full min-w-0  rounded-md sm:text-sm border-gray-300" />
                </div>
              </div>

              <div class="sm:col-span-6">
                <label for="resource_project_id" class="block text-sm font-medium text-gray-700">
                  Project
                </label>
                <div class="mt-1">
                  <select name="donated_to_project_id"   autocomplete="country-name" class="shadow-sm focus:ring-indigo-500  py-3 border px-2 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
                    <option>Choose Project</option>
                    <option v-for="project in projectList" :key="project.id" :value="project.id">{{project.project_name}}</option>
                  </select>
                </div>
              </div>

              <div class="flex justify-center sm:col-span-6">
                <button @click.prevent="create(loggedInUser.id)" class="block bg-blue-600 hover:bg-blue-500 p-2 rounded-lg w-1/2 mx-auto text-white mt-4 text-center px-40 text-center mx-auto">Save</button>

              </div>

            </div>
          </div>


        </div>
      </form>
    </div>
  </div>
</template>

<script>
import donations from "@/store/Donations";
import project from "@/store/Project";
import { onMounted } from "vue";
import user from "@/store/User";
import {ArrowLeftIcon} from '@heroicons/vue/solid'

export default {
  name: "DashboardAddDonation",
  components:{ArrowLeftIcon},
  setup(){
    const {loadProjects,projectList} = project;
    const {loggedInUser} = user

    const {create} = donations
    onMounted(()=>{
      if (loggedInUser.value){
        loadProjects(loggedInUser.value.id);
      }
    })
    return{
      create,
      projectList,
      loggedInUser
    }
  }
};
</script>

<style scoped>

</style>