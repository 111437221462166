<template>
  <Header v-if="showNavbar"/>
  <router-view />
  <Footer  v-if="showNavbar"/>
  <Notifications :show="showToast"/>
</template>
<script>
import { defineComponent } from "vue";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import {ref,provide} from 'vue';
import toast from "@/helpers/toast";
import Notifications from "@/components/Notifications";
export default defineComponent({
  name: "App",
  components: {
    Footer,
    Header,Notifications
  },
  setup(){
    const showNavbar = ref(true);
    const {showToast} = toast;
    provide('showNavbar',showNavbar )
    return {showNavbar,showToast}
  }
});
</script>
<style>
</style>
