import { computed, ref } from "vue";
import {useLoading} from 'vue-loading-overlay'
import toast from "@/helpers/toast";
import money from "@/helpers/money";
import FundraisingHost from '@/helpers/FundraisingHost'

const $loading = useLoading();
const projectList = ref(<any[]>[]);
const {format} = money;
const {success,error} = toast;
const projectName  = ref();
const filter = ref('');
const projectSummary  = ref();
const projectDescription  = ref();
const projectTargetAmount  = ref();
const projectTargetCurrency  = ref();
const selectedProject = ref();
const image  = ref();
const createdBy = ref();
const projects_for_carousel = ref();
const resetProject = () => {
   projectName.value = null;
   projectSummary.value = null;
   projectDescription.value = null;
   projectTargetAmount.value = null;
   projectTargetCurrency.value = null;
   image.value = null;
   createdBy.value = null;
}

const createProject = async () => {
  const loader = $loading.show({
    color: "#2581ef",
    opacity: .7,
  });
  const f =  document.getElementById('create-project') || null;
  // @ts-ignore
  const form = new FormData(f);
  form.append('project_description', projectDescription.value);
  const res = await fetch(`${FundraisingHost.url}create-project` ,{
    method: 'POST',
    body:form,
    headers:{
      'Accept':'application/json, text/plain',
      'Authorization' : 'Bearer '+localStorage.getItem('token')
    }
  });

  const data =  await res.json();
  if (data.status === 'success') {
    success(data.message);
    if (f){
      // @ts-ignore
      f.reset();
    }
    loadProjects()
  }else{
    error(data.message);
  }
  loader.hide();
}
const loadProjects = async (user_id = 'Doq1dA4wl5') => {
  const loader = $loading.show({
    color: "#2581ef",
    opacity: .7,
  });
  const res = await fetch(`${FundraisingHost.url}get-project-list/${user_id}` ,{
    headers:{
      'Content-Type': 'application/json',
      'Accept':'application/json, text/plain',
      'Authorization' : 'Bearer '+localStorage.getItem('token')
    }
  });

  const data =  await res.json();
  if (data.status === 'success') {
    projectList.value = data.projects;
    projects_for_carousel.value = data.projects_for_carousel
  }else{
    error(data.message)
  }
  loader.hide();
}


const updateProject = async (user_id: any) => {
  const loader = $loading.show({
    color: "#2581ef",
    opacity: .7,
  });
  const f =  document.getElementById('create-project') || null;
  // @ts-ignore
  const form = new FormData(f);
  form.append('project_description', projectDescription.value);
  const res = await fetch(`${FundraisingHost.url}update-project/${selectedProject.value.id}/${user_id}` ,{
    method: 'POST',
    body:form,
    headers:{
      'Accept':'application/json, text/plain',
      'Authorization' : 'Bearer '+localStorage.getItem('token')
    }
  });

  const data =  await res.json();
  if (data.status === 'success') {
    success(data.message);
    loadProjects()
  }else{
    error(data.message);
  }
  loader.hide();
}



const deleteProject = async (user_id: any) => {
  const loader = $loading.show({
    color: "#2581ef",
    opacity: .7,
  });
  const res = await fetch(`${FundraisingHost.url}delete-project/${selectedProject.value.id}` ,{
    method:'PATCH',
    headers:{
      'Content-Type': 'application/json',
      'Accept':'application/json, text/plain',
      'Authorization' : 'Bearer '+localStorage.getItem('token')
    }
  });

  const data =  await res.json();
  if (data.status === 'success') {
    success(data.message)
    loadProjects(user_id);
  }else{
    error(data.message)
  }
  loader.hide();
}



const setMainProject = async (project_id:any, user_id: any,isMain:number) => {
  const loader = $loading.show({
    color: "#2581ef",
    opacity: .7,
  });

  const obj = {
    is_main_project : isMain.toString(),
  }
  const res = await fetch(`${FundraisingHost.url}update-project/${project_id}/${user_id}` ,{
    method: 'PATCH',
    body:JSON.stringify(obj),
    headers:{
      'Content-Type': 'application/json',
      'Accept':'application/json, text/plain',
      'Authorization' : 'Bearer '+localStorage.getItem('token')
    }
  });

  const data =  await res.json();
  if (data.status === 'success') {
    success(data.message);
  }else{
    error(data.message);
  }
  loader.hide();
}

const getProjectList = computed(() => {
  return projectList.value.filter((val)=>val.project_name.toLowerCase().indexOf(filter.value.toLowerCase()) !== -1)
})

const getProjectById  = async (id : number) => {
  const loader = $loading.show({
    color: "#2581ef",
    opacity: .7,
  });
  const res = await fetch(`${FundraisingHost.url}get-project-info/${id}` ,{
    headers:{
      'Content-Type': 'application/json',
      'Accept':'application/json, text/plain',
      'Authorization' : 'Bearer '+localStorage.getItem('token')
    }
  });

  const data =  await res.json();
  if (data.status === 'success') {
     selectedProject.value = data.project;
  }else{
    error(data.message)
  }
  loader.hide();
}

const getAmount = computed(() => {
  if (projectTargetAmount.value){
    return  format(projectTargetAmount.value);
  }
return  0
})

export default {
  projectList,loadProjects,
  createProject,projectName,
  projectDescription,
  projectSummary,
  projectTargetCurrency,createdBy,
  image,selectedProject,updateProject,
  deleteProject,
  setMainProject,
  projects_for_carousel,filter,getProjectList,getProjectById,getAmount}
