<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <header class="w-full p-4">
  <div class="text-center relative bg-white z-10">
    <div class="sm:flex sm:justify-between sm:items-center sm:p-4  sm:px-6 ">
      <div class="flex justify-start">
        <router-link to="/">
          <span class="text-red-900 font-bold text-lg" >JMS Fundraising Portal</span>
        </router-link>
      </div>

      <nav class="md:flex space-x-10">
        <router-link :to="{name:'projects'}" class="text-base font-medium text-gray-500 hover:text-gray-900">
          Projects
        </router-link>

        <router-link :to="{name:'faq'}" class="text-base font-medium text-gray-500 hover:text-gray-900">
          FAQs
        </router-link>
        <!-- <a href="#" class="text-base font-medium text-gray-500 hover:text-gray-900">
          About us
        </a> -->
      </nav>
     
    </div>


  </div>
  </header>
</template>

<script>

export default {
  
  setup() {
    return {
     
    }
  },
}
</script>