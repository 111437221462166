<template>
  <div class="mt-8">
    <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex justify-between items-center">
        <h2 class="text-xl leading-6 font-medium text-gray-900">Content Templates</h2>
        <router-link :to="{name:'dashboard-add-tables'}"  class="block bg-blue-600 hover:bg-blue-500 p-2 rounded-lg  px-5 text-white  text-center">Create</router-link>
      </div>
    </div>
    <div class="mt-12 max-w-6xl mx-auto">
      <ul role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        <li v-for="template in templateList" :key="template.name" class="col-span-1 bg-white border rounded-lg shadow divide-y divide-gray-200">
          <div class="w-full flex items-center justify-between p-6 space-x-6">
            <div class="flex-1 truncate">
              <div class="items-center mb-4">
                <h3 class="text-gray-900 text-sm font-medium truncate text-lg">{{ template.template_name }}</h3>
                <p class="text-gray-700 text-sm ml-0">{{template.project_name}}</p>
              </div>


              <span v-for="(field,index) in template.template_fields" :key="index" class="bg-gray-100  text-gray-900  mr-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block">{{field.name}}</span>
            </div>
          </div>
          <div>
            <div class="-mt-px flex divide-x divide-gray-200">
              <div class="w-0 flex-1 flex">
                <button @click.prevent="selectedTemplate = template; showPrompt = true" href="" class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500">
                  <TrashIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
                  <span class="ml-3">Delete</span>
                </button>
              </div>

              <div class="w-0 flex-1 flex">
                <button @click.prevent="selectedTemplate =  template; $router.push({name:'dashboard-edit-tables'})"  href="" class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500">
                  <PencilIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
                  <span class="ml-3">Edit</span>
                </button>
              </div>


              <div class="w-0 flex-1 flex">
                <button @click.prevent="selectedTemplate =  template; $router.push({name:'dashboard-data-tables'})"  href="" class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500">
                  <DatabaseIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
                  <span class="ml-3">Add Data</span>
                </button>
              </div>

            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <DeleteAlert item="Template" :show="showPrompt" @cancel="showPrompt = false" @delete="deleteTemplate(loggedInUser.id);showPrompt = false;" />
</template>

<script>
import { DatabaseIcon, TrashIcon,PencilIcon } from '@heroicons/vue/solid'
import DeleteAlert from "@/components/DeleteAlert";

const tables = [
  {
    name: 'Accountability',
    fields:['project', 'date','amount'],
  },
  // More people...
]
import template from "@/store/Template";
import {onMounted} from "vue";
import user from "@/store/User";
import { ref } from "vue";

export default {
  name: "DashboardTables",
  components:{
    TrashIcon,PencilIcon,DeleteAlert,DatabaseIcon
  },
  setup(){
    const {loadTemplate,templateList,selectedTemplate,deleteTemplate} = template;
    const {loggedInUser} = user;
    const showPrompt = ref(false)
    onMounted(()=>{
      if (loggedInUser.value){
        loadTemplate(loggedInUser.value.id)
      }
    })
    return{
   templateList,selectedTemplate,showPrompt,deleteTemplate,loggedInUser
    }
  }
};
</script>

<style scoped>

</style>