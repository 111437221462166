

import { defineComponent,onMounted } from "vue";
import project from "@/store/Project";
import FundraisingHost from '@/helpers/FundraisingHost'


export default defineComponent({
  name: "Home",
  components: {
  },
  setup(){
    const {loadProjects,projectList,selectedProject,projects_for_carousel} = project;

    onMounted(() => {
      loadProjects('Doq1dA4wl5')
    });
    return { selectedProject,
             projectList,
             projects_for_carousel,
             FundraisingHost
          }
  }
});
