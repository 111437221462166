<template>
  <div class="mt-8">
    <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex justify-between items-center">
        <h2 class="text-xl leading-6 font-medium text-gray-900">Users</h2>
        <router-link :to="{name:'dashboard-add-users'}" class="block bg-blue-600 hover:bg-blue-500 p-2 rounded-lg  px-5 text-white  text-center">Create</router-link>
      </div>
    </div>
    <div class="mt-12 max-w-6xl mx-auto">
      <ul role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        <li v-for="person in userList" :key="person.email" class="col-span-1 bg-white border rounded-lg shadow divide-y divide-gray-200">
          <div class="w-full flex items-center justify-between p-6 space-x-6">
            <div class="flex-1 truncate">
              <div class="flex items-center space-x-3">
                <h3 class="text-gray-900 text-sm font-medium truncate">{{ person.name }}</h3>
              </div>
              <p class="mt-1 text-gray-500 text-sm truncate">{{ person.email }}</p>
            </div>
            <div class="h-10 w-10 rounded-full bg-gray-50 flex justify-center items-center text-blue-500 font-bold text-lg">{{person.name[0]}}</div>

          </div>
          <div>
            <div class="-mt-px flex divide-x divide-gray-200">
              <div class="w-0 flex-1 flex">
                <button @click="selectedItem = person; showDelete = true" class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500">
                  <TrashIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
                  <span class="ml-3">Delete</span>
                </button>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <DeleteAlert :show="showDelete" item="User" @cancel="showDelete = false" @delete="deleteUser(selectedItem.id);showDelete = false;" />
</template>

<script>
import {  TrashIcon } from '@heroicons/vue/solid'
import user from "@/store/User";
import {onMounted,ref} from "vue";
import DeleteAlert from "@/components/DeleteAlert";

export default {
  name: "DashboardDonors",
  components: {
    TrashIcon,DeleteAlert
  },
  setup() {
    const {userList,loadUsers,deleteUser} = user;
    const showDelete = ref(false);
    const selectedItem = ref();
    onMounted(() => {
      loadUsers()
    })
    return {
      userList,showDelete,selectedItem,deleteUser
    }
  },
};
</script>

<style scoped>

</style>