<template>
  <div class="min-h-full text-left">
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog as="div" class="fixed inset-0 flex z-40 lg:hidden" @close="sidebarOpen = false">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>
        <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
          <div class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-blue-700">
            <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button type="button" class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" @click="sidebarOpen = false">
                  <span class="sr-only">Close sidebar</span>
                  <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <div class="flex-shrink-0 flex items-center px-4">
              <img class="h-8 w-auto" src="https://www.jms.co.ug/images/JMS_LOGO2.webp" alt="Easywire logo" />
            </div>
            <nav class="mt-5 flex-shrink-0 h-full divide-y divide-blue-800 overflow-y-auto" aria-label="Sidebar">
              <div class="px-2 space-y-1">
                <router-link v-for="item in navigation" :key="item.name"  :to="{name:item.href}" @click="activePage = item.name" :class="[item.name == activePage ? 'bg-blue-800 text-white' : 'text-blue-100 hover:text-white hover:bg-blue-600', 'group flex items-center px-2 py-2 text-base font-medium rounded-md']" :aria-current="item.current ? 'page' : undefined">
                  <component :is="item.icon" class="mr-4 flex-shrink-0 h-6 w-6 text-blue-200" aria-hidden="true" />
                  {{ item.name }}
                </router-link>
              </div>
              <div class="mt-6 pt-6">
                <div class="px-2 space-y-1">
                  <router-link v-for="item in secondaryNavigation" :key="item.name" :to="{name:item.href}" @click="activePage = item.name" :class="[item.name == activePage ? 'bg-blue-800 text-white' : 'text-blue-100 hover:text-white hover:bg-blue-600', 'group flex items-center px-2 py-2 text-base font-medium rounded-md']">
                    <component :is="item.icon" class="mr-4 h-6 w-6 text-blue-200" aria-hidden="true" />
                    {{ item.name }}
                  </router-link>
                </div>
              </div>
            </nav>
          </div>
        </TransitionChild>
        <div class="flex-shrink-0 w-14" aria-hidden="true">
          <!-- Dummy element to force sidebar to shrink to fit close icon -->
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden lg:flex lg:w-64 lg:flex-col lg:fixed lg:inset-y-0">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex flex-col flex-grow bg-blue-700 pt-5 pb-4 overflow-y-auto">
        <div class="flex items-center flex-shrink-0 px-4">
          <img class="h-8 w-auto" src="https://www.jms.co.ug/images/JMS_LOGO2.webp" alt="Easywire logo" />
        </div>
        <nav class="mt-5 flex-1 flex flex-col divide-y divide-blue-800 overflow-y-auto" aria-label="Sidebar">
          <div class="px-2 space-y-1">
            <router-link v-for="item in navigation" :key="item.name"  :to="{name:item.href}"  @click="activePage = item.name" :class="[item.name == activePage ? 'bg-blue-800 text-white' : 'text-blue-100 hover:text-white hover:bg-blue-600', 'group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md']" :aria-current="item.current ? 'page' : undefined">
              <component :is="item.icon" class="mr-4 flex-shrink-0 h-6 w-6 text-blue-200" aria-hidden="true" />
              {{ item.name }}
            </router-link>
          </div>
          <div class="mt-6 pt-6">
            <div class="px-2 space-y-1">
              <router-link v-for="item in secondaryNavigation" :key="item.name"  :to="{name:item.href}" @click="activePage = item.name" :class="[item.name == activePage ? 'bg-blue-800 text-white' : 'text-blue-100 hover:text-white hover:bg-blue-600', 'group flex items-center px-2 py-2 text-base font-medium rounded-md']">
                <component :is="item.icon" class="mr-4 h-6 w-6 text-blue-200" aria-hidden="true" />
                {{ item.name }}
              </router-link>
            </div>
          </div>
        </nav>
      </div>
    </div>

    <div class="lg:pl-64 flex flex-col flex-1">
      <div class="relative z-10 flex-shrink-0 flex h-16 bg-white border-b border-gray-200 lg:border-none">
        <button type="button" class="px-4 border-r border-gray-200 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500 lg:hidden" @click="sidebarOpen = true">
          <span class="sr-only">Open sidebar</span>
          <MenuAlt1Icon class="h-6 w-6" aria-hidden="true" />
        </button>
        <!-- Search bar -->
        <div class="flex-1 px-4 flex justify-between sm:px-6  lg:mx-auto lg:px-8 border-b">
          <div class="flex-1 flex">

          </div>
          <div class="ml-4 flex items-center md:ml-6">
            <!-- Profile dropdown -->
            <Menu as="div" class="ml-3 relative">
              <div>
                <MenuButton v-if="loggedInUser" class="max-w-xs bg-white rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 lg:p-2 lg:rounded-md lg:hover:bg-gray-50">
                  <div class="h-8 w-8 rounded-full bg-gray-50 flex justify-center items-center text-blue-500 font-bold text-lg">{{loggedInUser.name[0]}}</div>
                  <span  class="hidden ml-3 text-gray-700 text-sm font-medium lg:block"><span class="sr-only">Open user menu for </span>{{loggedInUser.name}}</span>
                  <ChevronDownIcon class="hidden flex-shrink-0 ml-1 h-5 w-5 text-gray-400 lg:block" aria-hidden="true" />
                </MenuButton>
              </div>
              <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                <MenuItems class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <MenuItem v-slot="{ active }">
                    <router-link :to="{name:'dashboard-settings'}" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">Settings</router-link>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <button @click.prevent="logoutUser" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 w-full text-left']">Logout</button>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
      </div>

      <router-view />

    </div>
  </div>
</template>

<script>
import user from "@/store/User";
import { ref , inject,onBeforeUnmount,onMounted,onBeforeMount} from 'vue'
import { useRouter } from "vue-router";
import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import {
  BellIcon,
  ClockIcon,
  CogIcon,
  CreditCardIcon,
  DocumentReportIcon,
  HomeIcon,
  MenuAlt1Icon,
  QuestionMarkCircleIcon,
  ScaleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  XIcon,
  ViewGridIcon,
  CurrencyDollarIcon,
  UsersIcon,
  TemplateIcon,DocumentDuplicateIcon,
} from '@heroicons/vue/outline'
import {
  CashIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  OfficeBuildingIcon,
  SearchIcon,
} from '@heroicons/vue/solid'

const navigation = [
  { name: 'Home', href: 'dashboard-home', icon: HomeIcon, current: true },
  { name: 'Projects', href: 'dashboard-projects', icon: ViewGridIcon, current: false },
  { name: 'Donation', href: 'dashboard-donations', icon: CurrencyDollarIcon, current: false },
  { name: 'Template', href: 'dashboard-tables', icon: TemplateIcon, current: false },
  { name: 'Resources', href: 'dashboard-resources', icon: DocumentDuplicateIcon, current: false },
  { name: 'Collections', href: 'dashboard-collections', icon: CashIcon, current: false },
]
const secondaryNavigation = [
  { name: 'Settings', href: 'dashboard-settings', icon: CogIcon },
  { name: 'Users', href: 'dashboard-users', icon: UserGroupIcon, current: false },

]

const statusStyles = {
  success: 'bg-green-100 text-green-800',
  processing: 'bg-yellow-100 text-yellow-800',
  failed: 'bg-gray-100 text-gray-800',
}

export default {
  components: {
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    BellIcon,
    CashIcon,
    CheckCircleIcon,
    ChevronDownIcon,
    ChevronRightIcon,
    MenuAlt1Icon,
    OfficeBuildingIcon,
    SearchIcon,
    XIcon,
    ViewGridIcon,
    CurrencyDollarIcon,
    UsersIcon,
    DocumentDuplicateIcon
  },
  setup() {
    const sidebarOpen = ref(false)
    const activePage = ref('Home');
    const showNavbar = inject('showNavbar');
    showNavbar.value =  null;
    const {loggedInUser,logoutUser} = user;
    const router = useRouter();
    const initials = ref();

    onBeforeUnmount(()=>{
      showNavbar.value = true;
    })

    onBeforeMount(() => {
      if (!loggedInUser.value){
        router.replace({name:'login'})
      }
    })


    return {
      navigation,
      secondaryNavigation,
      statusStyles,
      sidebarOpen,
      activePage,
      loggedInUser,
      logoutUser
    }
  },
}
</script>