<template>
  <div class="mt-8">
    <div class="mt-12 max-w-2xl mx-auto">
      <main class="flex-1">
        <div class="relative max-w-4xl mx-auto md:px-8 xl:px-0">
          <div class="pt-10 pb-16">
            <div class="px-4 sm:px-6 md:px-0">
              <h1 class="text-3xl font-extrabold text-gray-900">Settings</h1>
            </div>
            <div class="px-4 sm:px-6 md:px-0">
              <div class="py-6">
                <!-- Tabs -->
                <div class="lg:hidden">
                  <label for="selected-tab" class="sr-only">Select a tab</label>
                  <select id="selected-tab" name="selected-tab" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm rounded-md">
                    <option v-for="tab in tabs" :key="tab.name" :selected="tab.current">{{ tab.name }}</option>
                  </select>
                </div>
                <div class="hidden lg:block">
                  <div class="border-b border-gray-200">
                    <nav class="-mb-px flex space-x-8">
                      <a v-for="tab in tabs" :key="tab.name" :href="tab.href" :class="[tab.current ? 'border-purple-500 text-purple-600' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700', 'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm']">
                        {{ tab.name }}
                      </a>
                    </nav>
                  </div>
                </div>

                <!-- Description list with inline editing -->
                <div class="mt-12 max-w-2xl mx-auto" v-if="loggedInUser">
                  <form class="space-y-8 divide-y divide-gray-200">
                    <div class="space-y-8 divide-y divide-gray-200">
                      <div>

                        <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                          <div class="sm:col-span-6">
                            <label for="username" class="block text-sm font-medium text-gray-700">
                              Name
                            </label>
                            <div class="mt-1 flex rounded-md shadow-sm">
                              <input @keyup="name = $event.target.value" :value="loggedInUser.name" type="text" name="username" id="username" autocomplete="username" class=" px-3 flex-1 focus:ring-indigo-500 border py-3 focus:border-indigo-500 block w-full min-w-0  rounded-md sm:text-sm border-gray-300" />
                            </div>
                          </div>

                          <div class="sm:col-span-6">
                            <label for="email" class="block text-sm font-medium text-gray-700">
                              Email
                            </label>
                            <div class="mt-1 flex rounded-md shadow-sm">
                              <input @keyup="email = $event.target.value" :value="loggedInUser.email" type="email" name="email" id="email" autocomplete="email" class=" px-3 flex-1 focus:ring-indigo-500 border py-3 focus:border-indigo-500 block w-full min-w-0  rounded-md sm:text-sm border-gray-300" />
                            </div>
                          </div>


                          <div class="sm:col-span-6">
                            <label for="password" class="block text-sm font-medium text-gray-700">
                              Password
                            </label>
                            <div class="mt-1 flex rounded-md shadow-sm">
                              <input @keyup="password = $event.target.value" :value="password" type="password" name="password" id="password" class=" px-3 flex-1 focus:ring-indigo-500 border py-3 focus:border-indigo-500 block w-full min-w-0  rounded-md sm:text-sm border-gray-300" />
                            </div>
                          </div>


                          <div class="sm:col-span-6">
                            <label for="password_confirmation" class="block text-sm font-medium text-gray-700">
                              Password Confirmation
                            </label>
                            <div class="mt-1 flex rounded-md shadow-sm">
                              <input @keyup="password_confirmation = $event.target.value" :value="password_confirmation" type="password" name="password_confirmation" id="password_confirmation" class=" px-3 flex-1 focus:ring-indigo-500 border py-3 focus:border-indigo-500 block w-full min-w-0  rounded-md sm:text-sm border-gray-300" />
                            </div>
                          </div>

                          <div class="sm:col-span-6">
                            <label for="designation" class="block text-sm font-medium text-gray-700">
                              Designation
                            </label>
                            <div class="mt-1">
                              <select @change="user_designation = $event.target.value" :value="loggedInUser.user_designation" id="designation" name="designation" autocomplete="designation" class=" px-3 shadow-sm focus:ring-indigo-500  py-3 border px-2 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
                                <option value="other">Other</option>
                                <option value="admin">Admin</option>
                              </select>
                            </div>
                          </div>

                          <div class="flex justify-center sm:col-span-6">
                            <button @click.prevent="updateUser()" class="block bg-blue-600 hover:bg-blue-500 p-2 rounded-lg w-1/2 mx-auto text-white mt-4 text-center px-40 text-center mx-auto">Save</button>

                          </div>

                        </div>
                      </div>


                    </div>
                  </form>
                </div>

              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import user from "@/store/User";
const tabs = [
  { name: 'General', href: '#', current: true },
  // { name: 'Password', href: '#', current: false },
]

export default {
  components: {
  },
  setup() {
    const {registerUser, name,email,password,password_confirmation,user_designation,createdById,loggedInUser,updateUser} = user;

    return {
      tabs,
      registerUser, name,email,password,password_confirmation,user_designation,createdById,loggedInUser,updateUser
    }
  },
}
</script>

<style scoped>

</style>