<template>
  <div class="bg-white my-10">
    <h1 class="text-2xl font-bold text-center my-10">Checkout</h1>

    <main class="relative grid grid-cols-1 gap-x-16 max-w-7xl mx-auto lg:px-8 lg:grid-cols-2">


      <section aria-labelledby="summary-heading" class="pt-6 pb-12 md:px-10 lg:max-w-lg lg:w-full lg:mx-auto lg:px-0 lg:pt-0 lg:pb-24 lg:bg-transparent lg:row-start-1 lg:col-start-2">
        <div class="max-w-2xl mx-auto px-4 lg:max-w-none lg:px-0">
          <h2 id="summary-heading" class="sr-only">Order summary</h2>

          <dl>
            <dt class="text-sm font-medium">Amount due</dt>
            <dd class="mt-1 text-3xl font-extrabold ">{{format(donation)}} UGX</dd>
          </dl>

          <ul role="list" class="text-sm font-medium divide-y divide-white divide-opacity-10">
            <li v-for="product in products" :key="product.id" class="flex items-start py-6 space-x-4">
              <img :src="FundraisingHost.url +'get-image/'+selectedProject.project_photo_file_path" :alt="selectedProject.project_name" class="flex-none w-20 h-20 rounded-md object-center object-cover" />
              <div class="flex-auto space-y-1">
                <h3 class="text-blue-600">{{ selectedProject.project_name }}</h3>
                <p>{{ selectedProject.project_summary }}</p>
              </div>
            </li>
          </ul>

        </div>
      </section>

      <section aria-labelledby="payment-and-shipping-heading" class="py-16 lg:max-w-lg lg:w-full lg:mx-auto lg:pt-0 lg:pb-24 lg:row-start-1 lg:col-start-1">

        <form>
          <div>
             <input type="hidden" :value="selectedProjectId" name="donated_to_project_id">
            <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 px-3">
              <div class="sm:col-span-6">
                <label for="donor_name" class="block text-sm font-medium text-gray-700">
                  Donor Names
                </label>
                <div class="mt-1 flex rounded-md shadow-sm">
                  <input v-model="donor_name" type="text" name="donor_name" id="donor_name" autocomplete="donor_name" class="flex-1 px-3  focus:ring-indigo-500 border py-3 focus:border-indigo-500 block w-full min-w-0  rounded-md sm:text-sm border-gray-300" />
                </div>
              </div>

              <div class="sm:col-span-6">
                <label for="donor_organization" class="block text-sm font-medium text-gray-700">
                  Donor Organisation (Optional)
                </label>
                <div class="mt-1 flex rounded-md shadow-sm">
                  <input v-model="donor_organization" type="text" name="donor_organization" id="donor_organization" autocomplete="donor_organization" class="flex-1 px-3  focus:ring-indigo-500 border py-3 focus:border-indigo-500 block w-full min-w-0  rounded-md sm:text-sm border-gray-300" />
                </div>
              </div>

              <div class="sm:col-span-6">
                <label for="donor_mm_number" class="block text-sm font-medium text-gray-700">
                  Mobile Money Number
                </label>
                <div class="mt-1 flex rounded-md shadow-sm">
                   <vue-tel-input v-model="donor_mm_number"
                                  mode="international" 
                                  
                                  :autoDefaultCountry='false'
                                  @validate="validate"
                                  defaultCountry="UG"
                                  :onlyCountries="['UG']"
                                  :dropdownOptions="{
                                    showFlags: true,
                                    disabled: true
                                  }"
                                  :inputOptions="{
                                    maxlength: 15,
                                    styleClasses: 'py-3 rounded-md border border-gray-300'
                                  }"
                                  class="w-full "
                                 >
                    </vue-tel-input>
                </div>
              </div>

              <div class="sm:col-span-6">
                <label for="username" class="block text-sm font-medium text-gray-700">
                 Currency
                </label>
                <div class="mt-1 flex rounded-md shadow-sm">
                  <select disabled  v-model="currency"  id="currency" name="currency" class="flex-1 px-3  focus:ring-indigo-500 border py-3 focus:border-indigo-500 block w-full min-w-0  rounded-md sm:text-sm border-gray-300" >
                    <option value="UGX">Ugandan Shilling</option>
                  </select>

                </div>
              </div>

              <div class="sm:col-span-6">
                <label for="mobile_carrier" class="block text-sm font-medium text-gray-700">
                 Source of Funds
                </label>
                <div class="mt-1 flex rounded-md shadow-sm">
                  <select  v-model="mobile_carrier"  id="mobile_carrier" name="mobile_carrier" class="flex-1 px-3  focus:ring-indigo-500 border py-3 focus:border-indigo-500 block w-full min-w-0  rounded-md sm:text-sm border-gray-300" >
                    <option value=''>choose source</option>
                    <option value="AIRTEL">Airtel</option>
                    <option value="MTN">Mtn</option>
                    <option value="FLEXIPAY">Flexipay</option>
                  </select>

                </div>
              </div>


              <div class="flex justify-center sm:col-span-6">
                <button @click.prevent="makeDonation" :disabled="disableCheckout" class="block bg-blue-600 hover:bg-blue-500 p-2 rounded-lg w-1/2 text-white mt-4 text-center mx-auto">Continue</button>
              </div>

            </div>
          </div>
        </form>
      </section>
    </main>
  </div>
</template>

<script>
import checkout from "@/store/Checkout";
import project from "@/store/Project";
const products = [
  {
    id: 1,
    name: 'TWEGAITE TWEZIMBE',
    short_summary:' Aenean imperdiet. Etiam ut purus mattis mauris sodales aliquam. Curabitur vestibulum aliquam leo. In hac habitasse platea dictumst. Morbi mollis tellus ac sapien. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Suspendisse potenti.',
    imageSrc: '/images/landing.jpg',
    imageAlt: 'Front of zip tote bag with white canvas, white handles, and black drawstring top.',
  },
  // More products...
]
import {onMounted} from "vue";
import money from "@/helpers/money";
import FundraisingHost from '@/helpers/FundraisingHost'
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import {useRouter} from 'vue-router';

export default {
  components: {
      VueTelInput,
  },

  setup() {
    const {donation,selectedProjectId,customer,currency,amount,
      donor_name,donor_organization,donated_to_project_id,donor_mm_number,makeDonation,mobile_carrier,disableCheckout,validMobileNumber} = checkout;
    const {selectedProject} = project;
    const {format} = money;
    const router = useRouter();
    

    if(selectedProject.value == undefined || selectedProject.value.project_photo_file_path == undefined || selectedProject.value.project_name == undefined){

       let id = localStorage.getItem('id');
       location.href = '/show/'+id;
      
    }

    function validate(phoneObject){
      validMobileNumber.value = phoneObject.valid;
    }

    onMounted(()=>{
      
      localStorage.setItem('id',selectedProjectId.value);
      amount.value = donation.value;
      donated_to_project_id.value = selectedProjectId.value;

    });
    return {
      products,
      donation,
      selectedProjectId,
      customer,currency,amount,
      donor_name,donor_organization,
      donated_to_project_id,donor_mm_number,
      makeDonation,
      selectedProject,
      format,
      FundraisingHost, mobile_carrier,
      disableCheckout,
      validate
    }
  },
}
</script>